/* eslint-disable prefer-destructuring */
/* eslint-disable no-plusplus */
/* eslint-disable import/no-unresolved */
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import {
  getMessaging,
  getToken,
  onMessage,
} from 'firebase/messaging'
import { initializeApp } from 'firebase/app'
import moment from 'moment'
import { FULL_DATE_TIME } from '@/libs/filterDate'
import secureLocalStorage from '@/libs/secureLocalstorage'
import HistoryShipping from './HistoryShipping.vue'

window.onload = () => {
  const theElement = document.getElementById('chatFocusing')
  const scrollToBottom = node => {
    // eslint-disable-next-line no-param-reassign
    node.scrollTop = node.scrollHeight
  }
  scrollToBottom(theElement)
}

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY_KOMSHIP,
  authDomain: 'komship-ticketing.firebaseapp.com',
  projectId: 'komship-ticketing',
  storageBucket: 'komship-ticketing.appspot.com',
  messagingSenderId: '669211426801',
  appId: process.env.VUE_APP_FIREBASE_API_ID_KOMSHIP,
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)

// Initialize Firebase Cloud Messaging and get a reference to the service
const messaging = getMessaging()

export default {
  components: { vSelect, HistoryShipping },
  data() {
    return {
      ticketId: this.$route.params.ticket_id,
      loadingDataDetail: true,

      ticketStatus: 0,
      orderStatus: '',
      ticketNo: '',
      noResi: '',
      ekspedisi: '',
      seller: '',
      ticketType: '',
      customerName: '',
      description: '',
      files: [],

      // Chat
      loadingDataChat: false,
      messages: [],
      chatItem: '',
      fcmToken: '',
      fileChat: null,
      chatFileMode: false,
      imageFileChat: null,
      imageInitialFile: null,
      imageFile: null,
      itemsImageInitialFile: [],

      // cancel ticket
      loadingCancelTicket: false,
      moment,

      changeStatus: [
        {
          label: 'Perlu Tindakan Lanjut',
          value: 0,
        },
        {
          label: 'Sedang diproses',
          value: 2,
        },
      ],
      ticketStatusItem: null,

      userId: secureLocalStorage.get('userData'),

      // Validation Store Chat
      messageErrorStatus: false,
      activeSend: false,
      statusPriority: 0,

      datas: {},
      loading: true,
      dataDetailTiket: {},
      GrandTotal: '',
      shippingLogo: '',

      isShowChatboxPlaceholder: true,
      FULL_DATE_TIME,
    }
  },
  created() {
    this.receiveMessage()
    const theElement = document.getElementById('chatFocusing')
    if (theElement !== null) {
      const scrollToBottom = node => {
      // eslint-disable-next-line no-param-reassign
        node.scrollTop = node.scrollHeight
      }
      scrollToBottom(theElement)
    }
  },
  async mounted() {
    this.fetchDataFirebase()
    await this.fetchDetailTicket()
    this.readChat()
    this.getMessage()
  },
  methods: {
    async fetchDetailTicket() {
      this.messageErrorStatus = false
      await this.$http_collaborator.get(`/ticket/api/v1/ticket/mitra/list/${this.ticketId}`)
        .then(response => {
          const { data } = response.data
          this.ticketStatus = data.status_ticket
          this.orderStatus = data.order_status
          this.ticketNo = data.ticket_no
          this.ticketType = data.type_ticket
          this.customerName = data.customer
          this.description = data.description
          this.seller = data.brand
          this.ekspedisi = data.shipping
          this.shippingLogo = data.shipping_logo
          this.noResi = data.awb
          this.files = data.attachments
          this.loadingDataDetail = false
          this.statusPriority = data.status_priority
          this.dataDetailTiket = data
          this.GrandTotal = data.transaction_value
          this.loading = false
          setTimeout(() => {
            const theElement = document.getElementById('chatFocusing')
            const scrollToBottom = node => {
              // eslint-disable-next-line no-param-reassign
              node.scrollTop = node.scrollHeight
            }
            scrollToBottom(theElement)
          }, 500)
        })
        .catch(err => {
          this.loadingDataDetail = false
        })
    },
    getMessage() {
      this.$http_collaborator.get(`/ticket/api/v1/ticket/mitra/chat/${this.ticketId}`)
        .then(response => {
          const { data } = response.data
          this.messages = data
          this.loadingDataChat = false
        })
        .catch(err => {
          this.loadingDataChat = false
        })
    },
    storeChat() {
      this.loadingDataChat = true
      if (this.ticketStatusItem !== null) {
        const formData = new FormData()
        if (this.itemsImageInitialFile.length > 1) {
        // eslint-disable-next-line no-plusplus
          for (let i = 0; i < this.itemsImageInitialFile.length; i++) {
            if (this.itemsImageInitialFile[i] !== undefined) {
              if (this.chatItem) formData.append('message', this.chatItem)
              formData.append('attachments', this.itemsImageInitialFile[i])
            } else if (this.chatItem) formData.append('message', this.chatItem)
          }
        } if (this.itemsImageInitialFile.length <= 1) {
          if (this.itemsImageInitialFile[0] !== undefined) {
            formData.append('attachments', this.itemsImageInitialFile[0])
            formData.append('message', this.chatItem)
          } else if (this.chatItem) formData.append('message', this.chatItem)
        }
        this.$http_collaborator.post(`/ticket/api/v1/ticket/mitra/chat/${this.ticketId}?status=${this.ticketStatusItem}`, formData)
          .then(() => {
            this.chatItem = ''
            this.itemsImageInitialFile = []
            this.loadingDataChat = false
            this.ticketStatusItem = null
            this.chatFileMode = false
            this.getMessage()
          })
          .catch(err => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Failure',
                icon: 'AlertCircleIcon',
                text: err,
                variant: 'danger',
              },
            }, 2000)
            this.loadingDataChat = false
          })
      } else {
        this.messageErrorStatus = true
        this.loadingDataChat = false
      }
    },
    statusTicketVariant(data) {
      let resultVariant = ''
      if (data === 'Perlu Diproses') {
        resultVariant = 'light-primary'
      } else if (data === 'Sedang Diproses') {
        resultVariant = 'light-warning'
      } else if (data === 'Belum Diproses') {
        resultVariant = 'light-warning'
      } else if (data === 'Selesai') {
        resultVariant = 'light-success'
      } else if (data === 'Batal') {
        resultVariant = 'light-secondary'
      }
      return resultVariant
    },
    orderStatusVariant(data) {
      let result = ''
      if (data === 'Diajukan') {
        result = 'light-primary'
      } else if (data === 'Dipacking') {
        result = 'light-info'
      } else if (data === 'Dikirim') {
        result = 'light-warning'
      } else if (data === 'Diterima') {
        result = 'light-success'
      } else if (data === 'Retur') {
        result = 'light-danger'
      } else if (data === 'Batal') {
        result = 'light-dark'
      }
      return result
    },
    fetchDataFirebase() {
      Notification.requestPermission().then(permission => {
        if (!('permission' in Notification)) {
          Notification.permission = permission
        }
        // you got permission !
      }, rejection => {
        // handle rejection here.
      })
      getToken(messaging, { vapidKey: 'BLZr38POWZ6vwjTUx4v2vlPHK-3fiI-DMPY18tAbu1dpchDiAYMyR7l2PE3WbH5hOM55X2zBR_C-5BLrpUA1-ZM' }).then(currentToken => {
        if (currentToken) {
          Notification.requestPermission().then(permission => {
            if (permission === 'denied' || permission === 'default') {
              this.$refs['modal-alert-notification'].show()
            }
          })
          this.fcmToken = currentToken
          this.$http
            .post('/user/update-fcm-token', {
              user_id: this.userId.id,
              fcm_token: currentToken,
            })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: 'No registration token available. Request permission to generate one.',
              variant: 'danger',
            },
          }, 2000)
        }
      }).catch(err => {
        this.$refs['modal-alert-notification'].show()
      })
    },
    fileUrl: file => (file ? URL.createObjectURL(file) : null),
    receiveMessage() {
      try {
        onMessage(messaging, payload => {
          this.loadingDataChat = true
          this.fetchDetailTicket()
          this.fetchDataFirebase()
          setTimeout(() => {
            this.loadingDataChat = false
          }, 300)
        })
      } catch (err) {
        setTimeout(() => {
          this.loadingDataChat = false
        }, 300)
      }
    },
    handleCloseAlertCancel() {
      this.$refs['alert-cancel-ticket'].hide()
    },
    cancelTicket() {
      this.$refs['alert-cancel-ticket'].show()
    },
    submitCancelTicket() {
      this.loadingCancelTicket = true
      this.$http_collaborator.put(`/ticket/api/v1/ticket/mitra/cancel/${this.ticketId}`)
        .then(() => {
          this.fetchDetailTicket()
          this.loadingCancelTicket = false
          this.$refs['alert-cancel-ticket'].hide()
        }).catch(err => {
          this.loadingCancelTicket = false
        })
    },
    copyTicket(data) {
      /* Copy the text inside the text field */
      navigator.clipboard.writeText(data)

      /* Alert the copied text */
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `Copied the text ${data}`,
          icon: 'AlertCircleIcon',
          variant: 'warning',
        },
      }, 1000)
    },
    onChangeFile(event) {
      event.target.files.forEach(this.myFile)
    },
    myFile(data) {
      this.itemsImageInitialFile.push(data)
    },
    putFileChat(event) {
      event.target.files.forEach(this.myFile)
      this.chatFileMode = true
    },
    addFileChat(event) {
      event.target.files.forEach(this.myFile)
    },
    removeFileChat(data) {
      const findIndexObj = this.itemsImageInitialFile.findIndex(items => items.name === data.name)
      this.itemsImageInitialFile.splice(findIndexObj, 1)
      if (this.itemsImageInitialFile.length === 0) {
        this.chatFileMode = false
      }
    },
    readChat() {
      const formData = new FormData()
      formData.append('ticket_id', this.ticketId)
      formData.append('user_id', this.userId.id)
      this.$http_komship.post('/v1/ticket-mitra/chat/read-all', formData).then(() => {}).catch(err => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Failure',
            icon: 'AlertCircleIcon',
            text: err,
            variant: 'danger',
          },
        }, 2000)
      })
    },
    disableButtonCancel(data) {
      let result = false
      if (data === 'Selesai') {
        result = true
      } else if (data === 'Dibatalkan') {
        result = true
      }
      return result
    },
    getValueFile(v) {
      if (v && v.includes('/attachment/dev/')) {
        return v.replace('/attachment/dev/', '')
      } if (v && v.includes('/attachment/prod/')) {
        return v.replace('/attachment/prod/', '')
      }
      return v
    },
    getUrlPath(v) {
      const baseUrl = 'https://storage.googleapis.com/komerce-openapi-bucket'
      return baseUrl + v
    },
    validateTicketStatus() {
      this.messageErrorStatus = false
    },

    changeActiveSend() {
      this.activeSend = true
    },
    handleCloseAlert() {
      this.$refs['modal-alert-notification'].hide()
    },
    formatDate(value) {
      return moment(value).format('DD MMMM YYYY - hh:mm')
    },
    formatCurrency(value) {
      return new Intl.NumberFormat('id-ID', {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      }).format(value)
    },
    handleCutUrl(value) {
      return value.substring(value.lastIndexOf('/') + 1, value.length)
    },
    handleRegexImage(filename) {
      return /\.(png|svg|jpg|jpeg|gif|webp|jfif)$/i.test(filename)
    },
    handleRegexDoc(filename) {
      return /\.(xlsx|xls|doc|docx|pdf)$/i.test(filename)
    },
    handelRegexVideo(filename) {
      return /\.(mp4)$/i.test(filename)
    },
    handleExtension(value) {
      return value.split('.')
        .pop()
    },
    handlePasteChatbox(event) {
      event.preventDefault()
      const clipboardData = event.clipboardData || window.clipboardData

      if (!clipboardData) return

      const pastedText = clipboardData.getData('text/plain')
      const items = clipboardData.items

      if (pastedText) {
        document.execCommand('insertText', false, pastedText)
      }

      if (items.length > 0) {
        for (let i = 0; i < items.length; i++) {
          const item = items[i]

          if (item.type.indexOf('image') !== -1) {
            const file = item.getAsFile()
            this.myFile(file)
            this.chatFileMode = true
          }
        }
      }
    },
    messageAdapter(chat) {
      let link = null
      const convertToBoolean = text => {
        const anchorRegex = /<a\s+(?:[^>]*?\s+)?href=['"](.*?)['"][^>]*?>\[Lihat Bukti\]<\/a>/
        return anchorRegex.test(text)
      }
      const isProof = convertToBoolean(chat)

      if (typeof chat !== 'object' && !isProof) {
        const urlify = text => {
          const urlRegex = /(https?:\/\/[^\s]+)/g
          return text.replace(urlRegex, url => `<a href="${url}" target="_blank" class="text-white">${url}</a>`)
        }
        link = urlify(chat.replace(/\r\\/g, '<br>'))
      } else {
        link = chat
      }

      return link
    },
    renderMessage(chat) {
      const containsHTML = /<[^>]*>/g.test(chat)

      if (containsHTML) {
        return chat
      }

      const escapedChat = chat
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;')
        .replace(/'/g, '&#039;')
      return escapedChat.replace(/\r\\/g, '<br>')
    },
  },
}
